export default {
    comm: {  //共用     
        back: '返回',
        messagetitle: '单据信息',
        detailtitle: '详细信息',
        login: '登录',
        default: '首页',
        order: '订单',
        ordersearch: '订单查询',
        replydeliverytime:'回复交期',
        orderdetailsearch:'订单明细查询',
        orderchangesearch:'订单变更查询',
        orderdetail: '订单详情',
        orderdetailsearch:'订单详情查询',
        orderchangesearch:'订单变更查询',
        replydeliverytime:'回复交期',
        deliver: '发货',
        delivercar: '发货车',
        deliversearch: '发货查询',
        deliverdetail: '发货单详情', 
        deliverdetailEdit: '发货单-修改',
        returnQuery: '退货查询',
        returnDetail: '退货单详情',
        reconciliationsearch: '对账查询',
        reconciliationdetail: '对账单详情',
        ARSearch: '应收查询',
        report: '报表',
        orderexecution: '订单执行状况',
        inspectionresults: '检验结果',
        supplyassessment: '供货考核表',
        deliverdelay: '交货延迟分析表',
        operationmenu: '操作菜单',
        download: '下载中心',
        sortfile: '分类文件',
        inform:'通知中心',
        downloadprint: '进行中订单图纸下载',
        help: '帮助中心',
        bulletin:'公告',
        company: '厦门正航软件科技有限公司',
        number: '序',
        startdate: '开始日期',
        enddate: '结束日期',
        alltype: '所有类别',
        tip: '提示',
        message:'消息',
        unselectwarning:'请至少选择一项',
        viewmore:'查看更多',
        confirm:'确定',
        updatePassword:'修改密码成功',
        iswithtax:'是否含税',
        withtaxState:'含税',
        btnyes:'是',
        btnno:'否',
        selectexport:'请选择一个导出格式',
        selectprint:'请选择一个打印格式',
    },
    button: { //按钮
        confirm: '确认签收',
        veto: '否决',
        download: '下载图纸',
        downloadchange: '下载变更图纸',
        export: '导出',
        print: '打印',
        reset: '重 置',
        query: '查 询',
        loadMore:'展开更多条件',
        hideMore:'收起更多条件',
        check: '查看',
        affirm: '确认',
        Cancel:'取消',
        cancel: '取 消',
        determine: '确 定',
        modification:'修改',
        delete: '删除',
        save:'保存',
        addnew:'添加',
        replyDeliveryDate:'回复交期',
        batchconfirmation: '批量确认',
        batchshipment: '批量发货',
        batchexport: '批量导出',        
        batchPrint: '批量打印',
        exporttitle: '批量导出订单',
        confirmationText: '订单已签收并确认',
        exportTextA: '导出选中数据',
        exportTextB: '导出满足条件数据',
        printTextA:'打印选中数据',
        printTextB:'打印满足条件数据',
        vetoreason: '请输入否决原因',
        orderveto: '订单否决',
        upload: '导入',
        uploadtem: '导入模板下载',
        deletechoose: '删除选中产品',
        search: '搜 索',
        modifyaddress:'批量修改地址',
        addnew:'新增'
    },
    Login: { //登录页
        title: '正航供应商平台',
        database: '账套',
        userId: '帐户',
        password: '密码',
        rempsaaword: '记住密码',
        login: '登 录',
        environment: '环境配置',
        InstallPlugin: '安装插件',
        HardwareInfo: '硬件信息',
        DownloadCenter: '下载中心',
        databaseText: '账套不允许为空！',
        userIdText: '帐户不允许为空！',
        passwordText: '密码不允许为空！',
        prompt: '提示',
        failure: '登录失败！',
        userExist: '当前帐号已经在其他地方登录，如果您强制登录对方将登出，是否强制登录？',
        alreadyLogined: '当前浏览器已经有一个登录用户（帐套:{0}, 帐号:{1}).如果您强制登录，此用户将被登出，请确认是否登录？',
        screentip:'建议使用分辨率:宽1360以上,以获得最佳体验',
        loginload:'登录中',
        cookieload:'等待服务器启动中...'
    },
    Default: { //首页
        phonenumber: '客服服务热线',
        increaseOrder: '本月新增订单',
        dealOrder: '本月发货笔数',
        refundOrder: '本月退货笔数',
        list: '待办事项',
        confirmnumber: '待确认笔数',
        delivernumber: '待发货笔数',
        syspublic: '[系统公告]',
        sysupdate: '[系统更新]',
        newestdownload:'最新下载',
        nonewestdownload:'暂无最新下载',
        bulletin:'公告',
        nobulletin:'暂无公告',
        information:'消息区',
        noinformation:'暂无消息',
        printhavenew:'图纸有变更，请及时下载',
        printnochange:'暂无订单有图纸变更',
        haveaneworder:'有一笔新的订单[{0}],请及时确认',
        withoutneworder:'暂无新的订单',
        haveanewchange:'订单[{0}]有变更,请及时确认',
        withoutnewchange:'暂无订单有变更'
    },
    Home: {
        changepassword: '修改密码',
        billsetting: '单据设定',
        register: '登录信息',
        delivercar: '发货车',
        back: '退出',
        oldpassword: '旧密码：',
        newpassword: '新密码：',
        confirmpassword: '确认密码：',
        oldpswpls: '请输入旧密码',
        newpswpls: '请输入新密码',
        againpsw: '请再次输入密码',
        errpsw: '两次输入密码不一致!',
        symbolerr: '密码中不可包含特殊字符“<”或“>”',
        logininfo:'登录信息',
        createtime:'创建时间',
        lastlogindate:'登录时间',
        ipaddress:'本次登录IP',
        membertype:'用户类型',
        commonuser:'普通用户',
        tempuser:'临时账户',
        client:'客户',
        supplier:'供应商',
        unknow:'未知',
        toolbarShowStyle:'工具栏显示方式',
        showIcon:'只显示图标',
        showIconAndText:'显示图标和文本',
        showText:'只显示文本',
        fieldRawTextType:'栏位显示风格',
        showcodeandname:'显示代码和名称',
        showname:'显示名称',
        showcode:'显示代码',
        billsettingsucess:'单据设定成功',
        oldpwderror:'密码框内内容不得为空'
    },
    Order: { //订单
        messagenumber: '单据编号',
        messagedate: '单据日期',
        compName:'客户',
        inputpls: '请输入',
        inputchoose: '请选择',
        checkstate: '确认状态',
        goodsstate: '发货状态',
        amount: '总金额',
        operation: '操作',
        unshipped: '未发货',
        partialDel: '部分发货',
        shipped: '已发货',
        allshipments: '全部发货',
        unconfirmed: '未确认',
        unconfirmedchanges: '有变更未确认',
        confirm: '已签收确认',
        unsignedconfirmation: '未签收确认',
        confirmerr:"订单{0}因“不属于当前组织”或“不处于生效状态”导致确认失败",
        confirmerr2:"订单{0}确认失败",
        vetoorder:"订单已否决",
        billOrgIdErr:"存在不同单据组织[{0}]，不允许执行批次打印",
        billTypeIdErr:"存在不同单据类型[{0}]，不允许执行批次打印",
        exportPlaceHolder:'请选择导出格式',
        exportNoneholder: '未配置导出格式',
        nodata:'没有符合当前条件的数据'

    },
    OrderVary: { //变更单查询
        addnew:'新增',
        update:'修改',
        delete:'删除',
        searchVaryData:'变更查询',
        noData:'暂无变更单数据',
        billNo: '变更单号',
        purBillNo: '采购订单号',
        compName:'客户',
        currId:'币别',
        rowNo:'序号',
        rowCode:'标识号',
        varyKind:'变更方式',
        materialId:'产品代码',
        materialName:'产品名称',
        materialSpec:'产品规格',
        sQuantity:'交易数量',
        quantity:'基本数量',
        presentSQty:'免费数量',
        sPrice:'交易价',
        oAmountWithTax:'含税金额',
        deliveryDate:'交货日期',
        address:'收货地址',
        remark:'备注',
        rowState:'行项状态',
        rowStateName1:'正常',
        rowStateName2:'终止',
        rowStateName3:'转单冻结',
        varyField:'变更后',
        unVaryField:'变更前'
    },
    OrderDetail: { //订单详情
        rowNo:'序号',
        rowCode:'标识号',
        messagenumber: '单号',
        messagedate: '日期',
        compName: '客户',
        amount: '金额',
        checkstate: '确认状态',
        goodsstate: '发货状态',
        purchaser: '采购员',
        OAmountWithTax:'含税金额',
        remark: '备注',
        number: '序',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        changenumber: '交易数量',
        Quantity:'基本数量',
        freenumber: '免费数量',
        nonumber: '未转数量',
        price: '交易价',
        taxamount: '含税金额',
        deldate: '交货日期',
        address: '收货地址',
        rowState:'行项状态',
        tip: '打印提示:请选择报表文件',
        placeholder: '请选择报表格式',
        noneholder: '未配置报表打印格式',
        orderconfirmed:"订单已确认",
        orderauthority:"订单{0}没有查看权限，请联系管理员"
    },
    OrderDetailSearch: { //订单详情查询
        txtBillNo: '单据编号',
        txtCompName: '客户',
        txtRowNo: '序号',
        txtBillDate: '单据日期',
        txtMaterialId: '产品代码',
        txtMaterialName: '产品名称',
        txtMaterialSpec: '产品规格',
        txtSQuantity: '交易数量',
        txtQuantity: '基本数量',
        txtTotalSQty: '总数量',
        txtUnTransSQty: '未转数量',
        txtSPrice: '交易价',
        txtOAmountWithTax: '含税金额',
        txtDeliveryDate: '交货日期',
        txtMTONo: 'MTO',
        txtBatchNo: '批号',
        txtBillState: '发货状态',
        txtRowState: '行项状态',
        txtRowState1: '正常',
        txtRowState2: '终止',
        txtRowState3: '转单冻结',
        txtRemark: '备注',
        errcurrentstate:'勾选单据{0}的单据已结案，无法进行发货，请重新勾选。'
    },
    Deliver: { //发货
        prodId: '单号/产品代码',
        prodName: '产品名称',
        compName: '客户',
        address: '送货地址',
        inputpls: '请输入',
        deliverDate: '交货日期',
        mtoNo:'MTO',
        batchNo:'批号',
        nearthreeday: '近3天',
        nearsevday: '近7天',
        nearthirtyday: '近30天',
        custom: '自定义',
        choosedate: '选择日期',
        OrderNo: '采购单号',
        ProdName: '产品名称',
        ProdId: '产品代码',
        ProdSpec: '产品规格',
        CarQuantity: '已转发货车数量',
        SQuantity: '交易数量',
        TranQty: '已转数量',
        DeliverDate: '交货日期',
        UnTransSQty: '未转数量',
        Quantity: '发货数量',
        TotalQty:'发货基本数量',
        UnitName:'基本单位',
        remark: '备注',
        address: '收货地址',
        allCheck: '全选',
        checkNum: '共选择',
        number: '笔',
        total:'总计{0}笔',
        addToCar: '加入发货车',
        deliver: '直接发货',
        message: '请输入正确的发货数量',
        success: '已成功加入发货车',
        warning: '请至少选择一项',
        resetnow:'重置当前单据',
        resetdefault:'重置为默认',
        unabledelivertip:'提示:单号{0}含税状态与当前页面过滤条件不同，已被过滤',
        upload:'点击上传',
        limitupload:'只能上传xlsx,xls文件',
        unabledelivertip2:"请检查订单{0}是否关键信息有误或者未转数量为0!",
        excellimittip:"请上传文件格式为.xlsx,.xls的Excel文件",
        excelemptytip:"请上传表格内容不为空的文件",
        difforgaddress:'所选行项中含有不同组织的单据，请重新选择',
        pagesize:'笔/页',
        exceldataerr:"导入的excel文件{0}中数据与服务器中的不匹配，已被过滤。若被过滤单据中存在含税状态与当前页面勾选不同的单据，请先切换勾选状态后再进行导入。",
        excelincomplete:"{0}的数据中，必填字段信息未填，已被过滤。",
        excelerrtitle:"Excel中部分数据已被过滤",
        excelerrno:" 第{0}行"
    },
    DeliverCar: { //发货车
        inputpls: '请输入',
        OrderNo: '单号',
        ProdId: '产品代码',
        ProdName: '产品名称',
        ProdSpec: '产品规格',
        UnTransSQty: '未转数量',
        MTONo:'MTO',
        BatchNo:'批号',
        remark: '备注',
        address: '收货地址',
        operation: '操作',
        number: '数量',
        allCheck: '全选',
        checkNum: '共选择',
        number: '笔',
        addToCar: '加入发货车',
        deliver: '直接发货',
        deleteMessage: '删除成功',
        message: "请至少选择一项",
        zerotip:'订单{0}未转数量为0，不可发货，请及时移出发货车',
        qzerotip:'订单{0}笔数为0或为空，不可发货，请输入正确的数量',
        deliverloading:'提交订单中',
        changeaddress:'修改地址中'
    },
    DeliverSuccess: { //发货成功
        success: '发货成功',
        billno: '发货单号',
        print: '打印发货单',
        check: '查看发货单',
        texttip: '点击发货单号查看该单据详情',
        totalcount:'总共选择{0}笔产品',
        successcount:'成功提交{0}笔产品'
    },
    DeliverSearch: { //发货查询 
        messagenumber: '单据编号',
        messagedate: '单据日期',
        orgName: '客户',
        inputpls: '请输入',
        inputchoose: '请选择',
        purchaser: '采购员',
        warestate: '入库状态',
        cancelstate: '退货状态',
        operation: '操作',
        nowarehousing: '未入库',
        partialwarehousing: '部分入库',
        allwarehousing: '全部入库',
        unreturned: '未退货',
        partialreturn: '部分退货',
        fullreturn: '全部退货',
        allaccrefund: '有账款全退',
        isdelete:'是否要删除？',
    },
    DeliverDetail: { //发货单详情
        messagenumber: '单据编号',
        messagedate: '单据日期',
        orgName: '客户',
        OAmountWithTax:'含税金额',
        amount: '金额',
        warestate: '入库状态',
        cancelstate: '退货状态',
        purchaser: '采购员',
        remark: '备注',
        number: '序',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        deliverynumber: '送货数量',
        carrySQty:'送货基本数量',
        receiptnumber: '收货数量',
        receivingQty: '收货基本数量',
        valueSQty: '计价数量',
        passnumber: '合格数量',
        nonumber: '不合格数',
        price: '交易价',
        subtotal: '小计',
        address:'收货地址',
        numbertip:'请输入正确的数量',
        editaddresses:'同个收货申请单收货地址需相同，请确认是否要修改地址',
    },
    ReturnQuery: { //退货查询 
        billNo: '单据编号',
        billDate: '单据日期',
        orgName: '客户',
        inputpls: '请输入',
        inputchoose: '请选择',
        purchaser: '采购人员',
        oAmountWithTax:'含税金额',
        operation: '操作' 
    },
    ReturnDetail: { //退货详情
        billNo: '单据编号',
        billDate: '单据日期',
        orgName: '客户',
        OAmountWithTax:'含税金额',
        amount: '金额',
        warestate: '入库状态',
        cancelstate: '退货状态',
        purchaser: '采购员',
        remark: '备注',
        number: '序',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        deliverynumber: '送货数量',
        receiptnumber: '退货总数量',
        receivingQty: '退货基本数量',
        valueSQty: '计价数量',
        presentSQty: '免费数量',
        passnumber: '合格数量',
        nonumber: '不合格数',
        price: '交易价',
        subtotal: '小计',
        backReasonName:'退货原因',
        address:'收货地址'
    },
    ReconciliationSearch: { //对账查询
        messagenumber: '单据编号',
        messagedate: '单据日期',
        compName:'客户',
        inputpls: '请输入',
        inputchoose: '请选择',
        payment: '付款条款',
        taxamount: '含税金额',
        remark: '备注',
        operation: '操作',
    },
    ReconciliationDetail: { //对账单详情
        messagenumber: '单号',
        messagedate: '日期',
        purchaser: '采购员',
        amount: '金额',
        taxes: '税金',
        taxamount: '含税金额',
        remark: '备注',
        number: '序',
        codeid: '物料代码',
        name: '物料名称',
        specifications: '物料规格',
        pricenumber: '计价数量',
        price: '单价',
        sourcetype: '来源单种类',
        sourcenumber: '来源单号',
        purnumber: '采购订单'
    },
    ReplyDeliveryTime:{ //回复交期
        rowNo:'序号',
        fromBillNo:'来源单号',
        confirmSQty:'答交数量',
        confirmDelDate:'答交日期',
        confirmResult:'备注',
        operation: '操作',
        pleaseEnter:'请输入',
        tip:'回复交期提示',
        successMsg:'答交成功！',
        dateNullMsg:'日期为空，请填写！',
        dbNullMsg:'数据为空，请先添加再确认！'
    },
    ARSearch: { //应收查询
        messagedate: '日期',
        billTypeAuto: '期初应付',
        typeNameYF: '应付',
        typeNameYS: '应收',
        inputpls: '请输入',
        compName:'客户',
        documenttype: '单据种类',
        messagenumber: '单据编号',
        date: '单据日期',
        amountreceivable: '应收金额',
        currentamount: '本期核销金额 ',
        discountamount: '本期折扣金额',
        lateamount: '未核销金额',
        summary: '汇总'
    },
    InspectionResults: { //检验结果
        product: '产品',
        messagedate: '检验日期',
        sourcenumber: '来源订单号',
        inputpls: '请输入',
        inputPls: '请输入产品代码或名称',
        inputchoose: '请选择时间',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        deliverynumber: '送货数量',
        receiptnumber: '收货数量',
        passnumber: '合格数量',
        nonumber: '不合格数',
        result: '判定结果'
    },
    OrderExecution: { //订单执行状况查询
        product: '产品',
        messagedate: '单据日期',
        order: '订单',
        inputpls: '请输入',
        inputPls: '请输入产品代码或名称',
        inputchoose: '请选择时间',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        MTONo:'MTO',
        BatchNo:'批号',
        changenumber: '交易数量',
        freenumber: '免费数量',
        allnumber: '总数量',
        price: '交易价',
        amount: '金额',
        taxamount: '含税金额',
        receivingSQty:'送货数量', // 取后台收货数量
        deliverydate: '预发货日',
        stockquantity: '已入库量',
        cancelnumber: '退货数量',
        shippedamount: '已发货未对账金额',
        reconciliationamount: '已对账含税金额',
        discountamount: '折让含税金额',
        amountreceivable: '应收账款金额'
    },
    SupplyAssessment: { //供货考核表
        product: '产品',
        messagedate: '采购日期',
        inputpls: '请输入产品代码或名称',
        inputchoose: '请选择时间',
        yearmonth: '年月',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        purchasenumber: '采购批数',
        deliverablenumber: '应交货批数',
        receiptnumber: '收货批数',
        cancelnumber: '退货批数',
        overduenumber: '逾期批数',
        specialnumber: '特采批数',
        rejectionnumber: '验退批数',
        overdueproportion: '逾期比例（%）',
        cancelproportion: '退货比例（%）'
    },
    DeliverDelay: { //交货延迟分析表
        product: '产品',
        messagedate: '预交货日',
        inputpls: '请输入产品代码或名称',
        inputchoose: '请选择时间',
        messagenumber: '单据编号',
        date: '单据日期',
        codeid: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        allnumber: '总数量',
        wareday: '最近入库日',
        stockquantity: '已入库量',
        delayday: '延迟天数'
    },
    Download: { //下载中心
        inputpls: '请输入',
        name: '名称',
        type: '分类',
        operation: '操作',
        intro: '简介',
        date: '日期',
        download: '下载',
        recupdate: '附件最近上传时间',
        accessoryname: '附件名称',
        orderinprogress: '进行中的订单',
        viewall: '查看所有',
        viewalldrawings: '查看所有图纸',
        viewchange: '只查看变更',
        viewchangedrawings: '只查看变更图纸',
        batchdownall: '批次下载所有图纸',
        batchdownchange: '批次下载变更图纸',
        clickdownload: '点击下载',
        downloadall: '批量下载',
        To: '至',
        unselecttip:'您选择的订单暂未有变更图纸！',
        noacc:'无附件',
        number:'序号',
        batchdownacc:'下载所有附件'
    },
    HelpCenter: { //帮助中心
        inputpls: '请输入',
        name: '名称',
        billNo:'单据编号',
        type: '分类',
        operation: '操作',
        date: '上传时间',
        updatetime: '更新时间',
        publishtime: '发布时间'
    },
    BulletinCenter:{//公告中心
        sysbulletin:'系统公告',
        salbulletin:'系统更新',
        epbulletin:'企业通讯',
        publisher:'发布人',
        maintitle:'主题',
        uplaodtime:'上传时间',
        sendtime:'发送时间'
    },
    Report:{
        qualified:'合格',
        disqualification:'不合格',
        other:'其他',
        withouttime:'时间范围不允许为空'
    }
}